import Cookies from 'js-cookie';
import axios from 'axios';
import {toast} from 'react-toastify';
import config from "../config";

class Auth {

    constructor() {
        this.token = null;
        this.loggedOutTokens = [];
        this.checkCookie();
    }

    checkCookie() {
        let token = Cookies.get('token');
        if (token != null) {
            this.token = token;
        }
    }

    login(username, password, callback) {
        let data = {username: username, password: password};

        this.post('login', JSON.stringify(data), false)
            .then(response => {

                this.token = response.data.content.token;
                this.updateCookie();
                callback('success');
            })
            .catch(error => {
                callback(this.formatError(error));
            });
    }

    post(request, data, addToken) {
        addToken = addToken || true;
        let config = {};
        if (addToken) {
            config = {headers: {Authorization: "Bearer " + this.token}};
        }
        return axios.post(this.formatUrl(request), data, config);
    }

    delete(request, data, addToken) {
        addToken = addToken || true;
        let config = {data: data};
        if (addToken) {
            config = {data: data, headers: {Authorization: "Bearer " + this.token}};
        }
        return axios.delete(this.formatUrl(request), config);
    }

    getUrl() {
        let active = config.apiUrl.active;
        if (active === 'auto') active = process.env.NODE_ENV;
        return config.apiUrl[active];
    }

    formatUrl(request) {
        return this.getUrl() + request;
    }

    get(request, addToken) {
        addToken = addToken || true;
        let config = {};
        if (addToken) {
            config = {headers: {Authorization: "Bearer " + this.token}};
        }
        return axios.get(this.formatUrl(request), config);
    }

    logout(callback) {
        if (this.token == null || this.loggedOutTokens.includes(this.token)) {
            callback('already_running');
            return;
        }
        this.post('logout', {})
            .then(response => {
                callback('success');
            })
            .catch(error => {
                callback(this.formatError(error));
            });

        this.loggedOutTokens.push(this.token);
        this.token = null;
        this.updateCookie();
    }

    newEventSource(url) {
        return new EventSource(this.formatUrl(url) + "?token=" + this.token, {withCredentials: true});
    }

    formatError(error) {
        if (!error.response) {
            return 'network_error';
        }
        let callbackResponse = error.response.status + " " + error.response.statusText
        let data = error.response.data;
        if (data.hasOwnProperty('type')) {
            callbackResponse = data.type;
        }
        return callbackResponse;
    }

    handleDefault(errorMessage, props) {
        if (errorMessage === 'unauthorized') {
            this.invalidToken(props);
            return;
        }
        if (errorMessage === 'forbidden') {
            props.history.push('/');
            toast.error('Du hast keinen Zugriff auf diese Seite!');
            return;
        }
        if (errorMessage === 'network_error') {
            toast.error('Es konnt keine Verbindung zum Backend hergestellt werden!');
            return;
        }
        if (errorMessage === 'invalid_token') {
            toast.error('Ungültiger Token!');
            return;
        }
        if (errorMessage === 'invalid_name') {
            toast.error('Nutzername enthält ungültige Zeichen oder ist nicht mindestens 4 Zeichen lang');
            return;
        }
        if (errorMessage === 'invalid_title') {
            toast.error('Titel enthält ungültige Zeichen oder ist nicht mindestens 4 Zeichen lang');
            return;
        }
        if (errorMessage === 'invalid_invite_token') {
            toast.error('Dieser Invite Token existiert nicht!');
            return;
        }
        if (errorMessage === 'name_taken') {
            toast.error('Dieser Nutzername ist bereits vergeben');
            return;
        }
        if (errorMessage === 'password_too_short') {
            toast.error('Passwort muss mindestens 6 Zeichen lang sein');
            return;
        }
        if (errorMessage === 'cant_delete_yourself') {
            toast.error('Du kannst nicht deinen eigenen Benutzer löschen!');
            return;
        }
        if (errorMessage === 'cant_kick_yourself') {
            toast.error('Du kannst nicht deinen eigenen Benutzer entfernen!');
            return;
        }
        if (errorMessage === 'not_member') {
            toast.error('Dieser Benutzer ist kein Mitglied mehr!');
            return;
        }
        if (errorMessage === 'invalid_userid') {
            toast.error('Dieser Benutzer existiert nicht mehr!');
            return;
        }
        if (errorMessage === 'cant_edit_yourself') {
            toast.error('Du kannst nicht deinen eigenen Benutzer anpassen!');
            return;
        }
        if (errorMessage === 'invalid_url') {
            toast.error('Ungültiger Link!');
            return;
        }
        if (errorMessage === 'internal_server_error') {
            toast.error('Ein interner Fehler ist aufgetreten!');
            return;
        }
        if (errorMessage === 'title_too_long') {
            toast.error('Der Titel darf maximal 100 Zeichen lang sein!');
            return;
        }
        toast.error(`Unknown Error (${errorMessage})`);
    }

    updateCookie() {
        if (this.token == null) {
            Cookies.remove('token');
        } else {
            Cookies.set('token', this.token, {expires: 365});
        }
    }

    isLoggedIn() {
        return this.token != null;
    }

    getToken() {
        return this.token;
    }

    setToken(token) {
        this.token = token;
    }

    invalidToken(props) {
        if (this.token == null) return;
        toast.error('Sitzung abgelaufen. Du wurdest ausgeloggt!');
        this.token = null;
        this.updateCookie();
        props.history.push('/login');
    }
}

export default new Auth();