const pageReducer = (page = {loaded: false, permissions: [], users: [], colorMapping: {}}, action) => {
    switch (action.type) {
        case 'UPDATE_PAGE':
            page = action.payload;
            return page;
        case 'RESET_PAGE':
            page = {loaded: false, permissions: [], users: [], colorMapping: {}};
            return page;
        default:
            return page;
    }
};

export default pageReducer;