import React, {useEffect, useState} from "react";
import Navigation from "../Navigation";
import {Button, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import Auth from '../../auth/Auth';
import {toast} from "react-toastify";
import ArrayUtil from "../../util/ArrayUtil";
import Moment from "react-moment";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import Modal from "react-bootstrap/Modal";

function Admin(props) {
    const [users, setUsers] = useState([]);
    const [registerTokens, setRegisterTokens] = useState([]);
    const [pages, setPages] = useState([]);

    const [formTitle, setFormTitle] = useState('');
    const [formOwnerId, setFormOwnerId] = useState('');

    const [showToken, setShowToken] = useState(false);
    const [userDeleteModal, setUserDeleteModal] = useState({show: false, user: {}});
    const [pageDeleteModal, setPageDeleteModal] = useState({show: false, page: {}});

    let updating = false;

    useEffect(() => {
        Auth.get('admin/user/list')
            .then(response => {
                setUsers(response.data.content);
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
        Auth.get('admin/registertoken/list')
            .then(response => {
                setRegisterTokens(response.data.content);
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
        loadPage();
    }, []);

    const loadPage = () => {
        Auth.get('admin/page/list')
            .then(response => {
                setPages(response.data.content);
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    const createRegisterToken = () => {
        if (updating) {
            toast.error('Bitte warte kurz!');
            return;
        }
        updating = true;
        Auth.post('admin/registertoken/create')
            .then(response => {
                let token = response.data.content;
                let newTokens = [...registerTokens];
                newTokens.push(token);
                setRegisterTokens(newTokens);
                toast.success('Token hinzugefügt');
                updating = false;
            })
            .catch(error => {
                updating = false;
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    const deleteRegisterToken = (registerToken) => {
        Auth.delete('admin/registertoken/delete', JSON.stringify({token: registerToken}))
            .then(response => {
                setRegisterTokens(ArrayUtil.remove(registerTokens, registerToken));
                toast.success('Register Token gelöscht');
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    const deletePage = () => {
        let page = pageDeleteModal.page;
        Auth.delete('admin/page/delete', JSON.stringify({pageId: page.id}))
            .then(response => {
                setPages(ArrayUtil.remove(pages, page));
                toast.success('Page gelöscht');
                setPageDeleteModal({show: false, page: {}});
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        let data = {title: formTitle, ownerUserId: formOwnerId};

        Auth.post('admin/page/create', data)
            .then(response => {
                loadPage();
                toast.success('Page erstellt');
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    const deleteUser = () => {
        let user = userDeleteModal.user;
        let userId = userDeleteModal.user.id;
        Auth.delete('admin/user/delete', JSON.stringify({userId: userId}))
            .then(response => {
                setUsers(ArrayUtil.remove(users, user));
                setUserDeleteModal({show: false, user: {}});
                toast.success('Benutzer wurde gelöscht')
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    return (
        <div className="container-fluid">
            <Navigation/>
            <br/>
            <div className="container bg-white shadow">
                <br/>
                <h2>Nutzer</h2>
                <br/>
                <Table>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nutzername</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map(globalUser => (
                        <tr key={globalUser.id}>
                            <td className="id">{globalUser.id}</td>
                            <td>{globalUser.username}</td>
                            <td>
                                <div className="row float-right">
                                    <div className="col-md-auto">
                                        <Link to={`admin/user/${globalUser.id}`}>
                                            <Button>
                                                <FontAwesomeIcon icon={faUserEdit}/>
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className="col-md-auto">
                                        <Button variant="danger"
                                                onClick={() => setUserDeleteModal({show: true, user: globalUser})}>
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </Button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <br/>
            </div>
            <br/>
            <div className="container bg-white shadow">
                <br/>
                <h2>Register Tokens</h2>
                <div className="row">
                    <div className="col-md-auto">
                        <Button variant="warning" onClick={createRegisterToken}>Neuer Token erstellen</Button>
                    </div>
                    <div className="col-md-auto">
                        <Button variant="danger" onClick={() => setShowToken(!showToken)}>
                            Tokens {showToken ? "ausblenden" : "einblenden"}
                        </Button>
                    </div>
                </div>
                <br/>
                <Table>
                    <thead>
                    <tr>
                        <th>Token</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {registerTokens.map(registerToken => {
                        let classNames = "";
                        if (!showToken) {
                            classNames = "blur";
                        }
                        let displayToken = 'XTOKENSX';
                        if (showToken) {
                            displayToken = registerToken;
                        }

                        return (
                            <tr key={registerToken}>
                                <td className={classNames}>{displayToken}</td>
                                <td align="right">
                                    <Button variant="danger" onClick={() => {
                                        deleteRegisterToken(registerToken);
                                    }}><FontAwesomeIcon icon={faTrash}/></Button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
                <br/>
            </div>
            <br/>
            <div className="container bg-white shadow">
                <br/>
                <h2>Pages</h2>
                <br/>
                <Table>
                    <thead>
                    <tr>
                        <th className="id">ID</th>
                        <th>Titel</th>
                        <th>Letzte Aktualisierung</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {pages.map(page => (
                        <tr key={page.id}>
                            <td>{page.id}</td>
                            <td>{page.title}</td>
                            <td><Moment locale="de" fromNow interval="30000">{page.lastUpdated}</Moment></td>
                            <td align="right">
                                <Button variant="danger" onClick={() => {
                                    setPageDeleteModal({show: true, page: page});
                                }}><FontAwesomeIcon icon={faTrash}/></Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <br/>
            </div>
            <br/>
            <div className="container bg-white shadow">
                <br/>
                <h2>Page erstellen</h2>
                <br/>
                <Form onSubmit={formSubmit}>
                    <Form.Group controlId="formTitle">
                        <Form.Label>
                            Titel
                        </Form.Label>
                        <Form.Control type="text" placeholder="Titel" value={formTitle}
                                      onChange={(e) => setFormTitle(e.target.value)} required/>
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label>
                            Besitzer User ID
                        </Form.Label>
                        <Form.Control type="text" placeholder="Besitzer User ID" value={formOwnerId}
                                      onChange={(e) => setFormOwnerId(e.target.value)} required/>
                    </Form.Group>
                    <Button variant="primary" type="submit">Page erstellen</Button>
                </Form>
                <br/>
            </div>
            <br/>
            <br/>

            <Modal show={userDeleteModal.show} onHide={() => setUserDeleteModal({show: false, user: {}})}>
                <Modal.Header closeButton>
                    <Modal.Title>VideoManager</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-danger">Möchtest du wirklich den Benutzer "{userDeleteModal.user.username}"
                        endgültig löschen?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deleteUser}>Benutzer löschen</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={pageDeleteModal.show} onHide={() => setPageDeleteModal({show: false, page: {}})}>
                <Modal.Header closeButton>
                    <Modal.Title>VideoManager</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-danger">Möchtest du wirklich die Page "{pageDeleteModal.page.title}"
                        endgültig löschen?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deletePage}>Page löschen</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Admin;