import React, {useEffect, useState} from "react";
import Auth from "../../auth/Auth";
import Navigation from "../Navigation";
import Moment from "react-moment";
import {Link, withRouter} from "react-router-dom";
import {Button, OverlayTrigger, Table} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
    faAngleRight,
    faAngleLeft,
    faAngleDoubleLeft,
    faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from "react-bootstrap/Tooltip";
import {useSelector} from "react-redux";

function PageLog(props) {

    const pageId = props.match.params.pageid;
    const site = parseInt(props.match.params.site);
    const page = useSelector(state => state.page);
    const [logs, setLogs] = useState({
        id: 'loading',
        log: []
    });

    useEffect(() => {
        loadPageLog();
    }, [site]);

    const loadPageLog = () => {
        Auth.get(`page/log/${pageId}/${site}`)
            .then(response => {
                setLogs(response.data.content);
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    const renderBackTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Zurück zur Page</Tooltip>;
    };

    return (
        <div className="container-fluid">
            <Navigation/>
            <br/>
            <div className="container shadow bg-light">
                <br/>
                <h3>{page.title} </h3>
                <div className="row">
                    <div className="col">
                        <h5>Zuletzt Aktualisiert: <Moment fromNow interval={1000}>{page.lastUpdated}</Moment></h5>
                    </div>
                    <div align="right" className="col">

                        <Link to={`/page/${pageId}`}>
                            <OverlayTrigger placement="bottom" overlay={renderBackTooltip}>
                                <Button>
                                    <FontAwesomeIcon icon={faArrowLeft}/>
                                </Button>
                            </OverlayTrigger>
                        </Link>
                    </div>
                </div>
                <br/>
            </div>
            <br/>
            <div className="container shadow bg-light">
                <br/>

                <div className="row">
                    <div className="col">
                        <h4>Logs <small>(Seite {site} von {page.maxSites})</small></h4>
                    </div>
                </div>
                <LogControls site={site} page={page} pageId={pageId}/>
                <br/>

                <Table>
                    <thead>
                    <tr>
                        <th>Typ</th>
                        <th>Details</th>
                        <th>Nutzer</th>
                        <th>Zeit</th>
                    </tr>
                    </thead>
                    <tbody>
                    {logs.log.map(log => {
                        return (
                            <tr key={log.type + log.time}>
                                <td className="id">{log.type}</td>
                                <td>{log.message}</td>
                                <td>
                                    {log.user.id === log.user.name &&
                                    <>{log.user.id}</>
                                    }
                                    {log.user.id !== log.user.name &&
                                    <>{log.user.name} ({log.user.id})</>
                                    }
                                </td>
                                <td><Moment fromNow interval={1000}>{log.time}</Moment></td>
                            </tr>
                        );

                    })}
                    </tbody>
                </Table>
                <br/>
                <LogControls site={site} page={page} pageId={pageId}/>
                <br/>
            </div>
            <br/>
        </div>
    );
}

const LogControls = withRouter(({site, page, pageId}) => {
    let lastPage = site - 1;
    if (lastPage <= 0) {
        lastPage = 1;
    }
    let nextPage = site + 1;
    if (nextPage > page.maxSites) {
        nextPage = page.maxSites;
    }
    return (
        <div className="row log-control-row">
            <div className="col-auto col-padding-small">
                <Link to={`/page/${pageId}/log/1`}>
                    <Button disabled={site <= 1}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft}/>
                    </Button>
                </Link>
            </div>
            <div className="col-auto col-padding-small">
                <Link to={`/page/${pageId}/log/${lastPage}`}>
                    <Button disabled={site <= 1}>
                        <FontAwesomeIcon icon={faAngleLeft}/>
                    </Button>
                </Link>
            </div>
            <div className="col-auto col-padding-small">
                <Link to={`/page/${pageId}/log/${nextPage}`}>
                    <Button disabled={site >= page.maxSites}>
                        <FontAwesomeIcon icon={faAngleRight}/>
                    </Button>
                </Link>
            </div>
            <div className="col-auto col-padding-small">
                <Link to={`/page/${pageId}/log/${page.maxSites}`}>
                    <Button disabled={site >= page.maxSites}>
                        <FontAwesomeIcon icon={faAngleDoubleRight}/>
                    </Button>
                </Link>
            </div>
        </div>
    );
});

export default PageLog;
