export const updatePage = (page) => {
    return {
        type: 'UPDATE_PAGE',
        payload: page
    }
};

export const resetPage = () => {
    return {
        type: 'LOGOUT_PAGE'
    }
};