const userReducer = (user = {loggedIn: false}, action) => {
    switch (action.type) {
        case 'UPDATE_USER':
            user = action.payload;
            user.loggedIn = true;
            return user;
        case 'LOGOUT_USER':
            user = {loggedIn: false};
            return user;
        default:
            return user;
    }
};

export default userReducer;