import React from 'react';
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";

function NotFound() {
    return (
        <div className="container center bg-white shadow small-container">
            <div align="center">
                <h1>Video Manager</h1>
                <h3>404 - Not Found</h3>
            </div>
            <br/>
            <div align="center">
                <Link to={"/"}>
                    <Button>
                        Zur Startseite
                    </Button>
                </Link>
            </div>
            <br/>
            <div className="row" align="center">
                <div className="col-sm">
                    <p className="copyright">© {new Date().getFullYear()} | <a
                        href="https://twitter.com/NotRedOre">RedOre</a></p>
                </div>
            </div>
        </div>
    );
}

export default NotFound;