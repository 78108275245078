class PageUtil {

    formatName(page, color) {
        let colorMapping = this.getColorMapping(page, color);
        if (colorMapping == null) return color;
        return colorMapping + ` (${color})`;
    }

    getColorMapping(page, color, defaultValue) {
        defaultValue = defaultValue || null;
        if (page.colorMapping != null) {
            if (page.colorMapping.hasOwnProperty(color)) {
                return page.colorMapping[color];
            }
        }
        return defaultValue;
    }

}

export default new PageUtil();