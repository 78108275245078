import Auth from "../auth/Auth";
import {updatePage} from "../actions/page.actions";
import {withRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useEffect} from "react";

export const loadPageInfo = async (pageId) => {
    return Auth.get(`page/info/${pageId}`);
};

export const updatePageInfo = (pageId, dispatch, props) => {
    loadPageInfo(pageId)
        .then(response => {
            dispatch(updatePage(response.data.content));
        })
        .catch(error => {
            let errorMessage = Auth.formatError(error);
            Auth.handleDefault(errorMessage, props);
        });
};

export const PageLoader = withRouter((props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        updatePageInfo(props.match.params.pageid, dispatch, props);
    }, []);

    return (props.children);
});