import React, {useState} from 'react';
import Auth from '../auth/Auth'
import {Navbar, OverlayTrigger} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Tooltip from "react-bootstrap/Tooltip";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignOutAlt, faInfo, faUsersCog} from '@fortawesome/free-solid-svg-icons';
import Modal from "react-bootstrap/Modal";
import {useSelector} from "react-redux";

function Navigation() {

    const user = useSelector(state => state.user);

    const [showModal, setShowModal] = useState(false);

    const renderUserTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Nutzer Einstellungen</Tooltip>;
    };
    const renderAdminTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Admin Panel</Tooltip>;
    };
    const renderLogoutTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Logout</Tooltip>;
    };
    const renderInfoTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Info</Tooltip>;
    };

    return (
        <div>
            <Navbar className="shadow" bg="dark" variant="dark" expand="lg">
                <Link to="/">
                    <Navbar.Text>Video Manager</Navbar.Text>
                </Link>
                <Navbar.Toggle/>
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        <Nav.Item>
                            <Navbar.Text>
                                <div className="row">
                                    <OverlayTrigger placement="bottom" overlay={renderUserTooltip}>
                                        <div className="col-md-auto">
                                            <Link to="/me">
                                                <Navbar.Text>{user.username}</Navbar.Text>
                                            </Link>
                                        </div>
                                    </OverlayTrigger>
                                    {user.admin ? (
                                        <OverlayTrigger placement="bottom" overlay={renderAdminTooltip}>
                                            <div className="col-md-auto">
                                                <Link to="/admin" className="nontextdoc">
                                                    <Button variant="outline-danger"><FontAwesomeIcon
                                                        icon={faUsersCog}/></Button>
                                                </Link>
                                            </div>
                                        </OverlayTrigger>
                                    ) : (
                                        <></>
                                    )}
                                    <OverlayTrigger placement="bottom" overlay={renderLogoutTooltip}>
                                        <div className="col-md-auto">
                                            <Link to="/logout"><Button variant="danger"><FontAwesomeIcon
                                                icon={faSignOutAlt}/></Button></Link>
                                        </div>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="bottom" overlay={renderInfoTooltip}>
                                        <div className="col-md-auto">
                                            <Button onClick={() => {
                                                setShowModal(true);
                                            }}><FontAwesomeIcon icon={faInfo}/></Button>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                            </Navbar.Text>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>VideoManager</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>API URL: {Auth.formatUrl('')}</p>
                    <p>UserID: {user.id}</p>
                </Modal.Body>
                <Modal.Footer>
                    <p className="copyright">© {new Date().getFullYear()} | <a href="https://twitter.com/NotRedOre">RedOre</a></p>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Navigation;