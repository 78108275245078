import React, {useEffect, useState} from "react";
import Navigation from "../Navigation";
import Auth from '../../auth/Auth';
import Moment from "react-moment";
import {Button, OverlayTrigger} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faInfo, faPlus, faTrash, faStream, faFilter} from '@fortawesome/free-solid-svg-icons';
import Tooltip from "react-bootstrap/Tooltip";
import {toast} from "react-toastify";
import './Page.css';
import ArrayUtil from "../../util/ArrayUtil";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import PageUtil from '../../util/PageUtil';
import config from '../../config'
import {useDispatch, useSelector} from "react-redux";
import Cookies from 'js-cookie';
import {updatePageInfo} from "../../services/PageService";

function Page(props) {

    const user = useSelector(state => state.user);
    const pageId = props.match.params.pageid;

    const page = useSelector(state => state.page);

    const dispatch = useDispatch();

    const [items, setItems] = useState([]);
    const [displayItems, setDisplayItems] = useState([]);
    const [deleteModal, setDeleteModal] = useState({show: false, item: {}});
    const [infoModal, setInfoModal] = useState({show: false, item: {}});
    const [editModal, setEditModal] = useState({show: false, item: {}, title: '', thumbnailUrl: '', comment: ''});
    const [filterModal, setFilterModal] = useState({show: false});
    const [massDeleteModal, setMassDeleteModal] = useState({show: false});

    const [filter, setFilter] = useState({color: null, type: null}); //{color: 'all', type: 'text'}
    const [sort, setSort] = useState('lastCreated'); //color, title, lastUpdated, type

    useEffect(() => {
        loadItems();
        loadFilter();

        let eventSource = Auth.newEventSource('page/update/' + pageId);
        eventSource.addEventListener('auth', evt => {
            console.log(evt);
            console.log(evt.data);
        });
        eventSource.addEventListener('page', evt => {
            if (evt.data === 'updateItems') {
                loadItems();
            }
            if (evt.data === 'updatePageInfo') {
                updatePageInfo(pageId, dispatch, props);
            }
        });
        return () => {
            eventSource.close();
        }
    }, []);

    const loadFilter = () => {
        let newFilter = Cookies.get('filter');
        if (newFilter)
            setFilter(JSON.parse(newFilter));
        let newSort = Cookies.get('sort');
        if (newSort)
            setSort(newSort);
    };

    useEffect(() => {
        let newItems = [...items]
        newItems = sortItems(newItems);
        setDisplayItems(newItems);
    }, [filter, sort]);

    useEffect(() => {
        let sortedItems = sortItems(items);
        setDisplayItems(sortedItems);
    }, [items]);

    const updateFilter = (filter) => {
        setFilter(filter);
        Cookies.set('filter', JSON.stringify(filter), {expires: 365});
    }

    const updateSort = (sort) => {
        setSort(sort);
        Cookies.set('sort', sort, {expires: 365});
    }

    const updateFilterFromModal = (e) => {
        e.preventDefault();
        updateSort(filterModal.sort);
        let newColor = filterModal.color;
        if (newColor === 'none') {
            newColor = null;
        }
        let newType = filterModal.type;
        if (newType === 'none') {
            newType = null;
        }
        let filter = {color: newColor, type: newType};
        updateFilter(filter);
        let newFilterModal = {...filterModal};
        newFilterModal.show = false;
        setFilterModal(newFilterModal);
    };

    const loadItems = (callback) => {
        Auth.get(`page/list/${pageId}`)
            .then(response => {
                let items = [...response.data.content];
                setItems(items);
                if (callback != null) {
                    callback(items);
                }
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            })
    };

    const sortItems = (items) => {
        items.sort((a, b) => b.createdDate - a.createdDate);
        if (filter.type) {
            items = items.filter(item => item.type === filter.type);
        }
        if (filter.color) {
            items = items.filter(item => item.color === filter.color);
        }
        if (sort === 'color') {
            items.sort((a, b) => a.color.localeCompare(b.color));
        } else if (sort === 'title') {
            items.sort((a, b) => a.title.localeCompare(b.title));
        } else if (sort === 'type') {
            items.sort((a, b) => a.type.localeCompare(b.type));
        } else if (sort === 'lastUpdated') {
            items.sort((a, b) => b.lastUpdate - a.lastUpdate);
        }
        return items;
    };

    const renderFilterTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Filter Einstellungen</Tooltip>;
    };

    const renderEditTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Page Einstellungen</Tooltip>;
    };

    const renderAddTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Eintrag hinzufügen</Tooltip>;
    };

    const renderLogTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Log Einträge</Tooltip>;
    };

    const renderMassDeleteTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Mehrere Einträge löschen</Tooltip>;
    };

    const renderDeleteItemTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Eintrag löschen</Tooltip>;
    };

    const renderEditItemTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Eintrag editieren</Tooltip>;
    };

    const renderInfoItemTooltip = (props) => {
        return <Tooltip {...props} show={props.show.toString()}>Eintrag Informationen</Tooltip>;
    };

    const deleteItem = () => {
        Auth.delete('page/delete', JSON.stringify({pageId: pageId, pageItemId: deleteModal.item.id}))
            .then(response => {
                let newItems = [...items];
                newItems = ArrayUtil.remove(newItems, deleteModal.item);
                setItems(newItems);
                setDeleteModal({show: false, item: {}});
                toast.success('Eintrag wurde gelöscht!');
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
                setDeleteModal({show: false, item: {}});
            });
    };

    const openEditModal = (item) => {
        if (hasPermission('edit')) {
            setEditModal({
                show: true,
                item: item,
                comment: item.comment,
                title: item.title,
                thumbnailUrl: item.thumbnailUrl,
                color: item.color
            });
        } else {
            toast.error('Du darfst keine Einträge editieren!');
        }
    };

    const updateEditTitle = (title) => {
        let newEditModal = {...editModal};
        newEditModal.title = title;
        setEditModal(newEditModal);
    };

    const updateEditThumbnailurl = (thumbnailUrl) => {
        let newEditModal = {...editModal};
        newEditModal.thumbnailUrl = thumbnailUrl;
        setEditModal(newEditModal);
    };

    const updateEditComment = (comment) => {
        let newEditModal = {...editModal};
        newEditModal.comment = comment;
        setEditModal(newEditModal);
    };

    const updateEditColor = (color) => {
        let newEditModal = {...editModal};
        newEditModal.color = color;
        setEditModal(newEditModal);
    };

    const updateItem = (item, data) => {
        let newItems = [...items];
        newItems = ArrayUtil.remove(newItems, item);
        let newItem = {...item};
        if (item.type === 'text') {
            newItem.title = data.title;
            newItem.thumbnailUrl = data.thumbnailUrl;
        }
        newItem.color = data.color;
        newItem.comment = data.comment;
        newItem.lastUpdate = Date.now();
        newItems.push(newItem);

        setItems(newItems);
    };

    const openFilterModal = () => {
        let color = filter.color;
        if (!color) {
            color = 'none';
        }
        let type = filter.type;
        if (!type) {
            type = 'none';
        }
        setFilterModal({show: true, color: color, type: type, sort: sort});
    };

    const itemEdit = (e) => {
        e.preventDefault();

        let data = {pageId: pageId, pageItemId: editModal.item.id};
        if (editModal.item.type === 'text') {
            data.title = editModal.title;
            data.thumbnailUrl = editModal.thumbnailUrl;
        }
        data.color = editModal.color;
        data.comment = editModal.comment;

        Auth.post('page/item/edit', JSON.stringify(data))
            .then(response => {
                updateItem(editModal.item, data);
                setEditModal(prevState => {
                    let newState = {...prevState}
                    newState.show = false;
                    return newState;
                });
                toast.success('Eintrag erfolgreich editiert');
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
                setEditModal(prevState => {
                    let newState = {...prevState}
                    newState.show = false;
                    return newState;
                });
            });
    };

    const reloadItem = (item) => {
        Auth.post('page/item/update', JSON.stringify({pageId: pageId, pageItemId: item.id}))
            .then(response => {
                loadItems((items) => {
                    items.forEach(newItem => {
                        if (newItem.id === item.id) {
                            if (infoModal.show) {
                                setInfoModal({show: true, item: newItem});
                            }
                        }
                    });
                });
                toast.success('Eintrag erfolgreich geupdatet');
            })
            .catch(error => {
                let errorMessage = Auth.formatError(error);
                Auth.handleDefault(errorMessage, props);
            });
    };

    const openDeleteModal = (item) => {
        if (hasPermission('delete')) {
            setDeleteModal({show: true, item: item});
        } else {
            toast.error('Du darfst keine Einträge löschen!');
        }
    }

    const openMassDeleteModal = () => {
        setMassDeleteModal({show: true});
    }

    const hasPermission = (permission) => {
        return page.permissions.includes(permission) || user.admin;
    };

    const startMassDelete = () => {
        let deleteAfter = Date.now() - 2592000000;
        let i = 0;
        items.map(value => {
            if (value.createdDate <= deleteAfter) {
                i++;
                Auth.delete('page/delete', JSON.stringify({pageId: pageId, pageItemId: value.id}))
                    .then(response => {
                        let newItems = [...items];
                        newItems = ArrayUtil.remove(newItems, deleteModal.item);
                        setItems(newItems);
                        toast.success('Eintrag wurde gelöscht!');
                    })
                    .catch(error => {
                        let errorMessage = Auth.formatError(error);
                        Auth.handleDefault(errorMessage, props);
                    });
                setMassDeleteModal({show: false})
            }
        })
        setTimeout(() => {
            toast.success(i + ' Einträge wurden gelöscht!');
        }, i * 400);
    };

    return (
        <div className="container-fluid">
            <Navigation/>
            <br/>
            <div className="container shadow bg-light">
                <br/>
                <h3>{page.title}</h3>
                <div className="row">
                    <div className="col">
                        <h5>Zuletzt Aktualisiert: <Moment fromNow interval={1000}>{page.lastUpdated}</Moment></h5>
                    </div>

                    <div align="right" className="col">
                        <div className="row float-right">
                            {hasPermission('add') &&
                            <div className="col">
                                <Link to={`/page/${pageId}/add`}>
                                    <OverlayTrigger placement="bottom" overlay={renderAddTooltip}>
                                        <Button variant="warning"><FontAwesomeIcon icon={faPlus}/></Button>
                                    </OverlayTrigger>
                                </Link>
                            </div>
                            }
                            {hasPermission('massdelete') &&
                            <div className="col">
                                <OverlayTrigger placement="bottom" overlay={renderMassDeleteTooltip}>
                                    <Button onClick={openMassDeleteModal} variant="danger"><FontAwesomeIcon
                                        icon={faTrash}/></Button>
                                </OverlayTrigger>
                            </div>
                            }
                            {hasPermission('pagelog') &&
                            <div className="col">
                                <Link to={`/page/${pageId}/log/1`}>
                                    <OverlayTrigger placement="bottom" overlay={renderLogTooltip}>
                                        <Button variant="secondary"><FontAwesomeIcon icon={faStream}/></Button>
                                    </OverlayTrigger>
                                </Link>
                            </div>
                            }
                            <div className="col">
                                <OverlayTrigger placement="bottom" overlay={renderFilterTooltip}>
                                    <Button onClick={openFilterModal} variant="info"><FontAwesomeIcon icon={faFilter}/></Button>
                                </OverlayTrigger>
                            </div>
                            <div className="col">
                                <Link to={`/page/${pageId}/edit`}>
                                    <OverlayTrigger placement="bottom" overlay={renderEditTooltip}>
                                        <Button><FontAwesomeIcon icon={faEdit}/></Button>
                                    </OverlayTrigger>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
            <br/>
            <div className="shadow bg-light page-item-container">
                <br/>
                {displayItems.map(item => {
                    let thumbnailUrl = item.thumbnailUrl;
                    if (thumbnailUrl == null || thumbnailUrl.trim() === '') {
                        thumbnailUrl = "/placeholder.png";
                    }

                    let link = item.originalUrl;
                    if (link === '') {
                        link = null;
                    }

                    return (
                        <div key={item.id} className="page-item shadow">
                            <a href={link}>
                                <img
                                    className="page-item-img shadow"
                                    src={thumbnailUrl}
                                    alt="Thumbnail"
                                    width="320"
                                    height="180"
                                />
                            </a>
                            <div className="page-item-control">
                                <OverlayTrigger placement="left" overlay={renderDeleteItemTooltip}>
                                    <Button className="shadow" variant="danger"
                                            onClick={() => openDeleteModal(item)}>
                                        <FontAwesomeIcon className="page-item-icon" icon={faTrash}/>
                                    </Button>
                                </OverlayTrigger>
                                <br/>
                                <OverlayTrigger placement="left" overlay={renderEditItemTooltip}>
                                    <Button className="shadow page-item-control-button" variant="warning"
                                            onClick={() => openEditModal(item)}>
                                        <FontAwesomeIcon className="page-item-icon" icon={faEdit}/>
                                    </Button>
                                </OverlayTrigger>
                                <br/>
                                <OverlayTrigger placement="left" overlay={renderInfoItemTooltip}>
                                    <Button className="shadow page-item-control-button"
                                            onClick={() => setInfoModal({show: true, item: item})}>
                                        <FontAwesomeIcon className="page-item-icon b" icon={faInfo}/>
                                    </Button>
                                </OverlayTrigger>
                            </div>
                            {PageUtil.getColorMapping(page, item.color) == null &&
                            <div className="page-item-status" style={{borderTopColor: item.color}}/>
                            }
                            {PageUtil.getColorMapping(page, item.color) != null &&
                            <OverlayTrigger
                                placement="top"
                                overlay={(props) => {
                                    return (
                                        <Tooltip {...props}>{PageUtil.formatName(page, item.color)}</Tooltip>
                                    )
                                }}>
                                <div className="page-item-status" style={{borderTopColor: item.color}}/>
                            </OverlayTrigger>
                            }
                            <div className="page-item-text">
                                <p>{item.title}</p>
                            </div>
                        </div>
                    )
                })}
                <br/>
            </div>
            <br/>
            <br/>
            <Modal show={deleteModal.show} onHide={() => setDeleteModal({show: false, item: {}})}>
                <Modal.Header closeButton>
                    <Modal.Title>Eintrag entfernen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-danger">Möchtest du wirklich den Eintrag
                        entfernen?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deleteItem}>Eintrag entfernen</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={infoModal.show} onHide={() => setInfoModal(prevState => {
                let newState = {...prevState};
                newState.show = false;
                return newState;
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Eintrag Informationen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Titel: {infoModal.item.title}</h4>
                    <br/>
                    <h5>ID: {infoModal.item.id}</h5>
                    <h5>
                        Letzte Aktualisierung: <Moment fromNow interval={1000}>{infoModal.item.lastUpdate}</Moment>
                    </h5>
                    {infoModal.item.url != null && infoModal.item.url !== '' &&
                    <h5>URL: <a href={infoModal.item.url}>{infoModal.item.url}</a></h5>
                    }
                    <h5>Type: {infoModal.item.type}</h5>
                    <br/>
                    <h5>Stats: </h5>
                    {infoModal.item.itemStats != null && infoModal.item.itemStats !== {} &&
                    Object.keys(infoModal.item.itemStats).map(key => {
                        let value = infoModal.item.itemStats[key];

                        return (
                            <p key={key}>{key}: {value}</p>
                        );
                    })
                    }
                    {infoModal.item.comment != null && infoModal.item.comment !== '' &&
                    <>
                        <br/>
                        <h5>Kommentar</h5>
                        <textarea readOnly rows="10" style={{width: "100%"}} value={infoModal.item.comment}/>
                    </>
                    }
                    {(hasPermission('update') && infoModal.item.type !== 'text') &&
                    <div className="row">
                        <div className="col-md-auto">
                            <Button variant="primary" type="submit" onClick={() => reloadItem(infoModal.item)}>
                                Daten neuladen
                            </Button>
                        </div>
                    </div>
                    }
                </Modal.Body>
            </Modal>
            <Modal show={editModal.show} onHide={() => setEditModal(prevState => {
                let newState = {...prevState}
                newState.show = false;
                return newState;
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Eintrag editieren</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Titel: {editModal.item.title}</h4>
                    <h5>ID: {editModal.item.id}</h5>
                    <br/>
                    <Form onSubmit={itemEdit}>
                        {editModal.item.type === 'text' &&
                        <Form.Group controlId="formTitle">
                            <Form.Label>
                                Titel
                            </Form.Label>
                            <Form.Control required type="text" placeholder="Titel" value={editModal.title}
                                          onChange={(e) => updateEditTitle(e.target.value)}/>
                        </Form.Group>
                        }
                        {editModal.item.type === 'text' &&
                        <Form.Group controlId="formThumbnailUrl">
                            <Form.Label>
                                Thumbnail Link
                            </Form.Label>
                            <Form.Control type="url" placeholder="Thumbnail Link"
                                          value={editModal.thumbnailUrl}
                                          onChange={(e) => updateEditThumbnailurl(e.target.value)}/>
                            <Form.Text>(Optional, Link direkt zum Bild)</Form.Text>
                        </Form.Group>
                        }
                        <Form.Group controlId="formComment">
                            <Form.Label>
                                Kommentar
                            </Form.Label>
                            <Form.Control rows="10" as="textarea" placeholder="Kommentar"
                                          value={editModal.comment}
                                          onChange={(e) => updateEditComment(e.target.value)}/>
                            <Form.Text>(Optional)</Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Farbe
                            </Form.Label>
                            <Form.Control
                                as="select"
                                value={editModal.color}
                                onChange={(e) => updateEditColor(e.target.value)}>
                                {config.colors.map(color => (
                                    <option key={color} value={color}
                                            style={{color: color}}>{PageUtil.formatName(page, color)}</option>
                                ))};
                            </Form.Control>
                        </Form.Group>
                        <div className="row">
                            <div className="col-md-auto">
                                <Button variant="primary" type="submit">Eintrag editieren</Button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={filterModal.show} onHide={() => setFilterModal(prevState => {
                let newState = {...prevState}
                newState.show = false;
                return newState;
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Filter Einstellungen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={updateFilterFromModal}>
                        <Form.Group controlId="formFilterColor">
                            <Form.Label>
                                Filtern nach Farbe
                            </Form.Label>
                            <Form.Control
                                required
                                as="select"
                                value={filterModal.color}
                                onChange={(e) => {
                                    setFilterModal(prevState => {
                                        let newState = {...prevState};
                                        newState.color = e.target.value;
                                        return newState;
                                    })
                                }}
                            >

                                <option key='none' value='none'>Keine Farbe</option>
                                {config.colors.map(color => (
                                    <option key={color} value={color}
                                            style={{color: color}}>{PageUtil.formatName(page, color)}</option>
                                ))};
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formFilter">
                            <Form.Label>
                                Filtern nach Typ
                            </Form.Label>
                            <Form.Control
                                required
                                as="select"
                                value={filterModal.type}
                                onChange={(e) => {
                                    setFilterModal(prevState => {
                                        let newState = {...prevState};
                                        newState.type = e.target.value;
                                        return newState;
                                    })
                                }}
                            >

                                <option key='none' value='none'>Kein Typ</option>
                                {config.types.map(type => (
                                    <option key={type} value={type}>{type}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formSort">
                            <Form.Label>
                                Sortieren nach
                            </Form.Label>
                            <Form.Control
                                required
                                as="select"
                                value={filterModal.sort}
                                onChange={(e) => {
                                    setFilterModal(prevState => {
                                        let newState = {...prevState};
                                        newState.sort = e.target.value;
                                        return newState;
                                    })
                                }}
                            >

                                <option key="lastCreated" value="lastCreated">Zuletzt erstellt</option>
                                <option key="color" value="color">Farbe</option>
                                <option key="title" value="title">Titel</option>
                                <option key="lastUpdated" value="lastUpdated">Zuletzt aktualisiert</option>
                                <option key="type" value="type">Typ</option>
                            </Form.Control>
                        </Form.Group>
                        <div className="row">
                            <div className="col-md-auto">
                                <Button variant="primary" type="submit">Filter aktualisieren</Button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={massDeleteModal.show} onHide={() => setMassDeleteModal(prevState => {
                let newState = {...prevState}
                newState.show = false;
                return newState;
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Mehrere Einträge löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-auto">
                            <p>Einträge älter als ein Monat</p>
                        </div>
                        <div className="col-md-auto">
                            <Button onClick={startMassDelete} variant="danger" type="submit">löschen</Button>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-md-auto">
                            <p className="text-danger">Achtung: Durch den klick auf "löschen" werden sofort alle
                                ausgewählen Einträge unwiderruflich gelöscht.</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Page;